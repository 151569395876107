
// $gray-900: #212529 !default;
// $primary: $gray-900;

// @import "~bootstrap/scss/bootstrap";

// .btn-primary {
// 	&:hover {
// 	  background: lighten($primary, 10%);
// 	}
//   }


// @keyframes "App-logo-spin" {
// 	from {
// 		transform: rotate(0deg);
// 	}
// 	to {
// 		transform: rotate(360deg);
// 	}
// }

// body {
// 	//background-color: #eee;
// 	//font-family: "Poppins", sans-serif;
// 	font-family: -apple-system, BlinkMacSystemFont, 'Nunito Sans', 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	background-color: #fdf9f9;
// }

// code {
// 	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }

// .App {
// 	text-align: center;
// }
// .App-logo {
// 	height: 40vmin;
// 	pointer-events: none;
// }
// .App-header {
// 	background-color: #282c34;
// 	min-height: 100vh;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	font-size: calc(10px + 2vmin);
// 	color: white;
// }
// .App-link {
// 	color: #61dafb;
// }
// ::-webkit-scrollbar {
// 	width: 20px;
// }
// ::-webkit-scrollbar-track {
// 	background-color: transparent;
// }
// ::-webkit-scrollbar-thumb {
// 	background-color: #d6dee1;
// 	border-radius: 20px;
// 	border: 6px solid transparent;
// 	&:hover {
// 		background-color: #a8bbbf;
// 	}
// }
// @media (prefers-reduced-motion: no-preference) {
// 	.App-logo {
// 		animation: App-logo-spin infinite 20s linear;
// 	}
// }


.fullscreen {
	width: 100%;
	height: 100%;
	overflow: hidden;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	min-height: 100vh;
  }
  .fullscreen .container,
  .fullscreen .container-wide {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-top: 80px;
	margin-bottom: 40px;
  }
  .fullscreen .container-fullscreen {
	width: 100%;
  }
  
  .halfscreen {
	width: 100%;
	height: 72%;
	overflow: hidden;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	min-height: 72vh;
  }
  .halfscreen .container,
  .halfscreen .container-wide {
	margin-top: 2%;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }
  .halfscreen .container-fullscreen {
	width: 100%;
  }
  
  @media (max-width: 1024px) {
	#header[data-transparent="true"] + main {
	  top: -120px;
	  margin-bottom: -124px;
	  position: relative;
	}
	#header[data-transparent="true"] + main > #page-title {
	  padding: 220px 0 160px 0;
	}
	#header[data-transparent="true"] + .fullscreen {
	  top: -80px;
	  margin-bottom: -80px;
	}
  }

  #myVideo {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
  }
  
  .content {
	position: fixed;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	color: #f1f1f1;
	width: 100%;
	padding: 20px;
  }
  
  .Banner {
	position: relative;
	text-align: center;
	margin-top: 250px;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
  }

  .myBtn {
	width: 200px;
	font-size: 18px;
	padding: 10px;
	border: none;
	background: #000;
	color: #fff;
	cursor: pointer;
  }
  
  .myBtn:hover {
	background: #ddd;
	color: black;
  }

  .phonelink {
	color: #fff;
	text-decoration: none;
  }

@media (max-width: 767px) {
	.col-lg-2 {
	  display: none;
	}
  }

//   .pushdown-10 {
// 	margin-bottom: 10px!important;
//   }

//   .pushtop-20 {
// 	margin-top: 20px!important;
//   }


  .badge {
	background-color: red;
	color: white;
  }


// label {
//     position:relative;
//     top:5px;
// }

// @media screen and (max-width: 768px) {
// 	.navbar-brand {
// 		width: auto; // Adjust as needed
// 		height: 50px; // Keeps the aspect ratio
// 		padding: 0px;
// 	}
// 	.navbar-brand img {
// 		max-width: 150px; // Adjust as needed
// 		height: 100%; // Keeps the aspect ratio
// 		padding:0;
// 		margin: 0!important;
// 		margin-left:5px!important;
// 	}
// 	.footer {
// 		display: none;
// 	}
// }

// .input-label input {
//     padding: 16px;
//     box-sizing: border-box;
//     width: 100%;
//     height: 56px;
//     border-radius: 10px;
//     border: 1px #D2C5B9 solid;
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 19.20px;
// }

// input:hover,
// input:focus {
//     outline: none;
//     border-color: #A6A6A6;
// }

.profile-bar {
    background-color: #f7da18;
    color: black;
    font-size: 12px; /* Adjust as needed */
    padding: 5px; /* Adjust as needed */
}

.btn-outline-primary {
	color: #412407;
	background-color: transparent;
	background-image: none;
	border-color: #412407;
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #412407;
	border-color: #412407;
}

.btn-outline-primary:active {
	color: #fff;
	background-color: #412407;
	border-color: #412407;
}

.btn-outline-primary:focus {
	color: #fff;
	background-color: #412407;
	border-color: #412407;
}



.btn-primary {
	background-color: #412407;
	border-color: #412407;
}

.btn-primary:hover {
	background-color: #704613;
	border-color: #412407;
}

.btn-primary:active {
	background-color: #412407;
	border-color: #412407;
}

.btn-primary:focus {
	background-color: #412407;
	border-color: #f7da18!important;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
	color: #fff;
	background-color: #412407;
	border-color: #f7da18!important;
}
.btn-primary:visited {
	background-color: #412407;
	border-color: #412407;
}

.btn-primary:disabled {
	background-color: #fefee8;
	border-color: #fefee8;
}

.btn-secondary {
	background-color: #c79707;
	border-color: #c79707;
}

.btn-secondary:hover {
	background-color: #e0a80d;
	border-color: #c79707;
}

.btn-secondary:active {
	background-color: #c79707;
	border-color: #c79707;
}

.btn-secondary:focus {
	background-color: #c79707;
	border-color: #c79707;
}

.btn-secondary:visited {
	background-color: #c79707;
	border-color: #c79707;
}

.btn-secondary:disabled {
	background-color: #fefee8;
	border-color: #fefee8;
}

.numeric-input input[type="text"] {
    text-align: right;
}
.numeric-input  {
    text-align: right;
}

.panel {
    // background-color: #fefee8;
    border: 1px solid #D2C5B9;
    box-sizing: border-box; /* This includes the padding and border in the element's total width and height */
    border-radius: 10px;
    padding: 32px;
}

.form-control:disabled, .form-control:read-only {
    background-color: #fdf9f9!important;
    border: 1px solid #acd3b6!important;
    color: #2c1f1f!important;
}

@media screen and (max-width: 767px) {
    .panel {
        padding: 10px;
    }
    form {
        margin-top: 10px;
        padding:0;
    }

    .shop-items {
        width: 100%;
    }

    .form-control {
        padding: 2px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 10px;
        border: 1px #D2C5B9 solid;
        font-size: 14px;
        font-weight: 300;
        line-height: 12px;
        height: 40px;
        margin-bottom: 10px;
    }

    .smallFont {
        font-size: 10px;
    }   
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #412407;
	border-color: #412407;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #412407;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.InovuaReactDataGrid__row:hover {
	background-color: #f7da18!important;
}

.text-primary {
	color: #412407!important;
}

a:hover {
	color: #412407!important;
}