.logo{
    // width: 200px;
    height: 40px;
    width: auto;
    /* margin-left: -49px;
    margin-top: -14px; */
}

.link-white {
    color: #fff;
}

a.link-white:hover, a.link-white:focus {
    color: rgb(212, 210, 210)!important;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255,255,255,.75)!important;
}

// .link-white:hover, .link-white:focus {
//     color: rgb(134, 134, 134);
//     --bs-bg-opacity: 1;
//     background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
// }

// .header {
//     font-family: "Poppins", sans-serif;
//     font-weight: 600;
//     font-size: 12px;
//     line-height: normal;
// }
// .navbar{
//     max-height: 85px;
//     height: 65px;
//     padding: 20px;
//     margin: 0px;
// }

// .nav{
//     float: right;
//     text-transform: uppercase;
//     font-size: 12px;
// }

// .header-userbox{

//     float: right;
//     margin: 10px;
//     font-size: 12px;
//     width: 220px!important;
    

// }

// .header-userbox.link-white{
//     margin-top: 10px;
// }

// @media screen and (max-width: 767) {
//     .logo{
//         width: auto!important;
//         height: 30px!important;
//         margin-left: -49px!important;
//         margin-top: -14px!important;
//     }
// }

// @media screen and (max-width: 1000) {
//     .logo{
//         width: auto!important;
//         height: 30px!important;
//         // margin-left: -49px!important;
//         // margin-top: -14px!important;
//     }
// }
// @media screen and (max-width:1000){
// .navbar-brand{
//     margin-top:-20px!important;
// }
// }


// .dropdown-menu {
//     width: 231px!important;
//     margin:0;
//     padding:0;
// }

// .dropdown-menu a:hover {
//     color: #fff!important;
//     background-color: #000!important;
// }



