.rounded-10 {
	border-radius: 1rem !important;
}
.product-card {
	min-height: 305px;
}
.product-card-img-wrap {
	overflow: hidden;
	height: 160px;
}
.product-card-img {
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}
.product-card-title {
 	height: 40px;
}

.product-card-img-cart {
	max-width: 100px;
}
.searchbox {
	width: 100%;
	min-width: 320px;
}

.imageProductPage {
	max-height: 400px!important;
	max-width: 200px!important;
  	width: auto!important;
	height: auto!important;
	filter:drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  
  }

  .imglogos{
	max-width: 160px!important;
	max-height: 160px!important;
	width: auto!important;
	height: auto!important;
  }

  .inputInGrid {
	max-width: 50px!important;

  }

  #shop-cart{
	border-left: 1px solid #d4c8c8;
	padding:10px;
  }

  #shop-cart:hover{
	cursor: ew-resize;
  }

  #shopCenter-div{
	border-left: 1px solid #d4c8c8;
  }

  .img-shoppingcart
  {
	max-height: 200px!important;
  
  }

  .filterbox {
	height: 50px;
  }

  #dropdown-item-button
  {
	background-image: linear-gradient(white, rgb(239, 239, 179));
	color:rgba(0, 0, 0, 0.5)
  }

  #dropdown-item-button:hover
  {
	background-image: linear-gradient(white, rgb(231, 231, 44));
	color:rgba(0, 0, 0, 1)
  }

  #shop-categories {
	background-image: linear-gradient(white, rgb(236, 236, 148));
  
  }

  .toggleButton
  {
		width: 40px;
		cursor: pointer;
  }

  .noPadding { padding-left: 0!important;}

  #searchButton {
	margin-left: -17px;
  }

  .toolsBarButtons {
	background-image: linear-gradient(white, rgb(239, 239, 179));
	color:rgba(0, 0, 0, 0.5);
	border: 1px solid #fcf4f4!important;
	border-radius: 0.25rem;
  }

  .toolsBarButtons:hover {
	background-image: linear-gradient(white, rgb(231, 231, 44));
	color:rgba(0, 0, 0, 1);
	border: 1px solid #423c3c;
  }

.btn-primary {
	border: 1px solid #a89e9e!important;
}

.pushdown-3
{
	margin-bottom: -1px!important;
}