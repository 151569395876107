
.panel {

    border: 1px solid #D2C5B9;
    box-sizing: border-box; /* This includes the padding and border in the element's total width and height */
    border-radius: 10px;
    padding: 32px;
}


a:hover {
    color: #5456F4;
    font-weight: bold;
}

a {
    color: #A6A6A6;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.80px;
    word-wrap: break-word;
    text-decoration: none;
}


label {
    position:relative;
    top:5px;
}

.numeric-input  {
    text-align: right;
}

.form-control:disabled {
    background-color: #F2F2F2!important;
    border: 1px solid #D2C5B9!important;
    color: #A6A6A6!important;
}

